import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getToken,
  postData,
  fetchDataFromAPI,
  updateData,
  deleteData,
  convertNumericAttributesToString,
  formatDate,
  getGridTexts,
  translate,
  formatDateString,
  useErrorHandler,
} from "../../utils/hooks";
import Login from "../Login";
import validator from "validator";
import Message from "../../Components/Message";
import ErrorMessage from "../../Components/ErrorMessage";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { Grid, FormControlLabel, Checkbox } from "@mui/material";
import * as constants from "../../utils/constants";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fr, it, enGB, pt, de, nl, es } from "date-fns/locale/";
import { TranslationContext } from "../../Components/TranslationContext";
import { useRef, useContext } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";

function ContractCharges() {
  const { errorMessage, handleAPIError } = useErrorHandler();
  let [token, setToken] = useState();
  const [dateFrom, setDateFrom] = useState(new Date());
  const [minDateFrom, setMinDateFrom] = useState(new Date());
  const [warningDateFrom, setWarningDateFrom] = useState(new Date());
  let [dialogMode, setDialogMode] = useState("create");
  const [error, setError] = useState(null);
  const [uidmessage, setUidmessage] = useState(0);
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [deleteUnpaidCharges, setDeleteUnpaidCharges] = useState(false);
  const [lastRent, setLastRent] = useState([]);
  const [calendarDialogClassName, setCalendarDialogClassName] = useState("");
  const textFieldsRefs = useRef({});
  const [data, setData] = useState([]);
  const [unpaidRents, setUnpaidRents] = useState([]);
  const [unpaidCharges, setUnpaidCharges] = useState([]);
  const [rentProperty, setRentProperty] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);
  const [allRecoverableCharges, setAllRecoverableCharges] = useState([]);
  const [formValues, setFormValues] = useState({
    charge_id: "",
    amount: "",
    dateFrom: new Date(),
  });
  const { translations } = useContext(TranslationContext);
  registerLocale("fr", fr);
  registerLocale("it", it);
  registerLocale("en", enGB);
  registerLocale("pt", pt);
  registerLocale("de", de);
  registerLocale("es", es);
  registerLocale("nl", nl);
  const dataGridTexts = getGridTexts();

  const displayedLabels = {
    charge_id: translations.charge,
    amount: translations.amount,
  };

  const [formErrors, setFormErrors] = useState({
    price: "",
    ccy: "",
    dateFrom: "",
  });

  const { contract_id } = useParams();
  const fetchApiData = async () => {
    // Read contracts prices
    await fetchDataFromAPI(API_URL_READ, requestOptions, setData, false)
      .then(() => {
        // Fetch all recoverable charges
        fetchDataFromAPI(
          API_URL_CHARGES,
          requestOptions,
          setAllRecoverableCharges,
          false
        ).then(() => {
          fetchDataFromAPI(
            API_URL_LAST_RENT,
            requestOptions,
            setLastRent,
            false
          );
        });
      })
      .catch((error) => {
        handleAPIError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchApiData(); // initial data fetching
  }, []);

  useEffect(() => {
    setRentProperty(data.rent_property);
  }, [data]);

  useEffect(() => {
    if (typeof unpaidRents !== "undefined" && unpaidRents.count > 0) {
      let msg = translations.warning_charge_date_set.replace(
        "%s",
        unpaidRents.count
      );
      setWarningMessage(msg);
    } else {
      setWarningMessage("");
    }
  }, [unpaidRents]);

  useEffect(() => {
    if (typeof unpaidCharges !== "undefined" && unpaidCharges.count > 0) {
      let msg = translations.warning_charge_date_delete
        .replace("%s1", unpaidCharges.count)
        .replace("%s2", formatDateString(unpaidCharges.from))
        .replace("%s3", formatDateString(unpaidCharges.to));
      setWarningMessage(msg);
    } else {
      setWarningMessage("");
    }
  }, [unpaidCharges]);

  useEffect(() => {
    if (
      typeof lastRent.firstUnpaidRent !== "undefined" &&
      lastRent.firstUnpaidRent !== ""
    ) {
      setMinDateFrom(new Date(lastRent.firstUnpaidRent.date));
    } else if (typeof lastRent.contract !== "undefined") {
      setMinDateFrom(new Date(lastRent.contract.dateFrom));
    }

    if (typeof lastRent.lastRent !== "undefined" && lastRent.lastRent !== "") {
      setWarningDateFrom(new Date(lastRent.lastRent.date));
    }
  }, [lastRent]);

  const handleDeleteUnpaidChargesCBChange = (event) => {
    setDeleteUnpaidCharges(event.target.checked);
  };

  const resetForm = () => {
    setFormValues({
      charge_id: "",
      amount: "",
      dateFrom: new Date(),
    });
    setDateFrom(new Date());
    setFormErrors({});
    textFieldsRefs.current = {};
    setWarningMessage("");
  };

  /**
   * Open dialog for create
   */
  const openDialogCreate = async () => {
    try {
      setDialogMode("create");
      setOpen(true);
      setWarningMessage("");
    } catch (error) {
      handleAPIError(error);
    }
  };

  /**
   * Open dialog for update
   */
  const openDialogUpdate = () => {
    if (selectionModel.length > 0) {
      const selectedItem = data.charges.find(
        (item) => item.id === selectionModel[0]
      );
      const convertedObj = convertNumericAttributesToString(selectedItem);
      delete convertedObj.dateTo;
      setFormValues(convertedObj);
      setDialogMode("edit");
      setOpen(true);
    }
  };

  /**
   * Open dialog for delete confirmation
   */
  const openDialogDelete = () => {
    if (selectionModel.length > 0) {
      fetchDataFromAPI(
        API_URL_UNPAID_CHARGES + selectionModel[0],
        requestOptions,
        setUnpaidCharges,
        false
      ).catch((error) => {
        handleAPIError(error);
      });
      setOpenDelete(true);
    }
  };
  const updateMessage = (msg) => {
    setMessage(msg);
    setUidmessage(Math.random());
  };

  /**
   * navigate to given page
   */
  const gotoPage = (page) => {
    navigate(`/${page}/${contract_id}`);
  };

  /**
   * navigate to appartment prices
   */
  const gotoChargesAdjustmentPage = () => {
    gotoPage("RentChargesAdjustment");
  };

  const handleTextFieldChange = (key) => (event) => {
    let updatedValue = event.target.value;
    if (key === "amount") {
      // Replace all non-numeric and non-decimal point characters by nothing
      updatedValue = updatedValue.replace(/[^\d.]/g, "");

      // Ensure that there is only one decimal point
      const parts = updatedValue.split(".");
      if (parts.length > 2) {
        updatedValue = `${parts[0]}.${parts.slice(1).join("")}`;
      }
    }

    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: updatedValue,
    }));
  };

  const handleDateFromChange = (dt) => {
    fetchDataFromAPI(
      API_URL_UNPAID_RENTS + dt.toISOString().substring(0, 10),
      requestOptions,
      setUnpaidRents,
      false
    ).catch((error) => {
      handleAPIError(error);
    });
    setDateFrom(dt);
    setFormValues((prevValues) => ({
      ...prevValues,
      dateFrom: dt,
    }));
    /*
    if (dt < warningDateFrom) {
      setWarningMessage(translations.warning_charge_date_set);
    } else {
      setWarningMessage("");
    }
    */
  };

  const handleCalendarEditClose = () => {
    setCalendarDialogClassName("");
  };

  const handleCalendarEditOpen = () => {
    setCalendarDialogClassName("priceEditDialog");
  };

  /**
   * Close create/edit dialog
   */
  const handleCloseDialog = () => {
    setOpen(false);
    resetForm();
  };

  /**
   * Close confirmation delete dialog
   */
  const handleCloseDeleteDialog = () => {
    setOpenDelete(false);
    resetForm();
  };

  /**
   * Delete selected elements and close confirmation dialog
   */
  const handleConfirmDeleteDialog = () => {
    selectionModel.forEach((element) => {
      const URL_DELETE = API_URL + "/" + deleteUnpaidCharges;
      deleteData(URL_DELETE, element, getToken())
        .then((response) => {
          fetchApiData();
        })
        .then(() => {
          updateMessage(translations.deletion_done);
        })
        .catch((error) => {
          handleAPIError(error);
        });
    });
    setOpenDelete(false);
    resetForm();
  };

  const API_URL_READ = `${constants.API_URL}contract/charge/${contract_id}`;
  const API_URL_CHARGES = `${constants.API_URL}recoverablecharge/${contract_id}`;
  const API_URL = `${constants.API_URL}contract/charge`;
  const API_URL_LAST_RENT = `${constants.API_URL}rent/last/${contract_id}`;
  const API_URL_UNPAID_RENTS = `${constants.API_URL}rent/countUnpaid/${contract_id}/`;
  const API_URL_UNPAID_CHARGES = `${constants.API_URL}contract/charge/countUnpaid/`;

  const handleSelectionChange = (newSelection) => {
    if (newSelection !== null && newSelection.length > 0) {
      //const selectedData = data.filter((el) => el.id === newSelection[0]);
      setSelectionModel(newSelection);
    }
  };

  const handleSaveDialog = () => {
    if (validateForm()) {
      formValues.contract_id = contract_id;
      formValues.user_id = localStorage.getItem("userId");
      const formToSend = {
        ...formValues,
      };
      if (dialogMode === "edit") {
        updateData(API_URL, formToSend.id, formToSend, getToken())
          .then((response) => {
            resetForm();
            setOpen(false);
            fetchApiData();
          })
          .then(() => {
            updateMessage(translations.update_done);
          })
          .catch((error) => {
            handleAPIError(error);
          });
      } else {
        postData(API_URL, formToSend, getToken())
          .then((response) => {
            resetForm();
            setOpen(false);
            fetchApiData();
          })
          .then(() => {
            updateMessage(translations.update_done);
          })
          .catch((error) => {
            resetForm();
            setOpen(false);
            handleAPIError(error);
          });
      }
      resetForm();
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {
      charge_id: "",
      amount: "",
      dateFrom: "",
    };

    /**
     * All fields are mandatory
     */
    for (const [key, value] of Object.entries(formValues)) {
      if (typeof value !== "object") {
        if (value == null || validator.isEmpty(value)) {
          isValid = false;
          errors[key] = translations.required_field.replace(
            "%s",
            displayedLabels[key]
          );
        }
      }
    }

    setFormErrors(errors);
    return isValid;
  };

  const columns = [
    {
      field: "dateFrom",
      headerName: translations.from_date,
      flex: 1,
      valueFormatter: formatDate,
      editable: true,
    },
    {
      field: "charge_id",
      headerName: translations.charge,
      flex: 1,
      editable: true,
      align: "left",
      headerAlign: "left",
      valueFormatter: (params) => {
        //TODO n'afficher que les charges qui n'ont pas encore été définies

        //revoir ça : récupérer les charges affichables et toutes les charges pour pouvoir récupérer le label

        const foundCharge = allRecoverableCharges.find(
          (o) => o.id === params.value
        );
        return foundCharge ? translate(translations, foundCharge.label) : "";
      },
    },
    {
      field: "amount",
      headerName: translations.amount,
      flex: 1,
      editable: true,
      align: "right",
      headerAlign: "right",
    },
  ];
  const auth = `Bearer ${getToken()}`;
  const requestOptions = {
    headers: {
      Authorization: auth,
    },
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer className="toolbarContainer">
        <Button onClick={openDialogCreate} variant="outlined" color="primary">
          {translations.add}
        </Button>
        <Button
          onClick={openDialogUpdate}
          variant="outlined"
          color="primary"
          disabled={selectionModel.length === 0}
        >
          {translations.edit}
        </Button>
        <Button
          onClick={openDialogDelete}
          variant="outlined"
          color="primary"
          disabled={selectionModel.length === 0}
        >
          {translations.delete}
        </Button>
        <Button
          onClick={gotoChargesAdjustmentPage}
          variant="outlined"
          color="primary"
        >
          {translations.charges_adjustment}
        </Button>
      </GridToolbarContainer>
    );
  };

  if (!getToken() || getToken() === null) {
    return <Login setToken={setToken} />;
  }
  return (
    <div>
      <h1>
        {translations.charges}&nbsp;&nbsp;&nbsp;<i>{rentProperty}</i>
      </h1>
      <ErrorMessage message={errorMessage} />
      <Message message={message} />
      {loading ? (
        <p>{translations.loading}</p>
      ) : error ? (
        error.response && error.response.status === 401 ? (
          <Login setToken={setToken} />
        ) : (
          <p>Error: {error.message}</p>
        )
      ) : (
        <div className="flex flexHorizontalCenter">
          <div className="dataGridContainer">
            <DataGrid
              sx={{
                color: "primary.main",
              }}
              localeText={
                dataGridTexts.components.MuiDataGrid.defaultProps.localeText
              }
              rows={data.charges}
              columns={columns}
              //getRowClassName={getRowClassName}
              editMode="cell"
              components={{
                Toolbar: CustomToolbar,
              }}
              selectionModel={selectionModel}
              onRowSelectionModelChange={(newSelectionModel) => {
                handleSelectionChange(newSelectionModel);
              }}
            />
          </div>
        </div>
      )}
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSaveDialog();
          }
        }}
      >
        <DialogTitle className="calendarDialog">
          {dialogMode === "edit"
            ? translations.update_charge
            : translations.add_charge}
        </DialogTitle>
        <DialogContent className={calendarDialogClassName}>
          <br></br>
          <Grid container spacing={2} direction="column">
            {Object.entries(formValues).map(([key, value]) => {
              if (key !== "id" && key !== "user_id" && key !== "contract_id") {
                if (key === "charge_id") {
                  return (
                    <Grid item key={key}>
                      <InputLabel id="select-label-charge">
                        {translations.charge}
                      </InputLabel>
                      <Select
                        labelId="select-label-charge"
                        id="select-charge"
                        value={value}
                        label={translations.charge}
                        onChange={handleTextFieldChange(key)}
                        error={!!formErrors[key]}
                        style={{ width: "100%" }}
                      >
                        {allRecoverableCharges &&
                          allRecoverableCharges.map((ch, index) => {
                            if (ch.canDisplay) {
                              return (
                                <MenuItem key={ch.id} value={ch.id}>
                                  {ch.label &&
                                    translate(translations, ch.label)}
                                </MenuItem>
                              );
                            }
                            return null;
                          })}
                      </Select>
                      {formErrors[key] && (
                        <FormHelperText error>{formErrors[key]}</FormHelperText>
                      )}
                    </Grid>
                  );
                } else if (key === "dateFrom") {
                  return (
                    <Grid item key={key}>
                      <InputLabel id="select-label-dateFrom">
                        {translations.from_date}
                      </InputLabel>
                      <div onKeyDown={(e) => e.preventDefault()}>
                        <DatePicker
                          showIcon
                          selected={dateFrom}
                          minDate={minDateFrom}
                          onChange={(date) => handleDateFromChange(date)}
                          locale={localStorage
                            .getItem("userLanguage")
                            .substring(0, 2)}
                          className="datePicker"
                          dateFormat="P"
                          onCalendarClose={handleCalendarEditClose}
                          onCalendarOpen={handleCalendarEditOpen}
                        />
                      </div>
                    </Grid>
                  );
                } else
                  return (
                    <Grid item key={key}>
                      <TextField
                        label={displayedLabels[key]}
                        inputRef={(ref) => (textFieldsRefs.current[key] = ref)}
                        value={value}
                        onChange={handleTextFieldChange(key)}
                        error={!!formErrors[key]}
                        helperText={formErrors[key]}
                      />
                    </Grid>
                  );
              }
              return null;
            })}
            <Grid item key={"warningMessage"}>
              {warningMessage && (
                <div className="warningDialogMessage">
                  <p>{warningMessage}</p>
                </div>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{translations.cancel}</Button>
          <Button onClick={handleSaveDialog}>{translations.save}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDelete} onClose={handleCloseDeleteDialog}>
        <DialogTitle>{translations.deletion_confirmation_title}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} direction="column">
            <Grid item key={"deleteInformation"}>
              {translations.deletion_confirmation}
            </Grid>
            {typeof unpaidCharges !== "undefined" &&
              unpaidCharges.count > 0 && (
                <Grid item key="deleteUnpaidUserChoice">
                  <FormControlLabel
                    control={
                      <Checkbox onChange={handleDeleteUnpaidChargesCBChange} />
                    }
                    label={translations.delete_charge_for_unpaid_rents}
                  />
                </Grid>
              )}
            <Grid item key={"warningMessage"}>
              {warningMessage && (
                <div className="warningDialogMessage">
                  <p>{warningMessage}</p>
                </div>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>
            {translations.cancel}
          </Button>
          <Button onClick={handleConfirmDeleteDialog}>
            {translations.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ContractCharges;
