import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, useState, useContext } from "react";
import Button from "@mui/material/Button";
import MuiLink from "@mui/material/Link";
import * as constants from "../../utils/constants";
import Message from "../../Components/Message";
import { fetchTranslations } from "../../utils/hooks";
import { TranslationContext } from "../../Components/TranslationContext";

// Fonction pour vérifier si une adresse e-mail est valide
function isEmailValid(email) {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return emailRegex.test(email);
}

async function loginUser(credentials) {
  return fetch(`${constants.API_URL}auth/login`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
    //credentials: "omit",
  })
    .then((data) => {
      if (data.ok) {
        return data.json();
      } else {
        const error = (data && data.message) || data.status;
        return Promise.reject(error);
      }
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
}

function Login({ setToken }) {
  const navigate = useNavigate();
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("error");
  const [timeOutDuration, setTimeOutDuration] = useState(30000);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const msg = searchParams.get("msg");
  const { translations, updateTranslations } = useContext(TranslationContext);

  useEffect(() => {
    // Vérifiez si le message est défini dans l'URL, puis affichez-le une seule fois.
    if (msg !== "" && msg !== null) {
      setMessageType("info");
      setMessage(msg);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");

    // Vérifiez si l'adresse électronique est valide
    if (!isEmailValid(username)) {
      setMessage(translations.invalid_email);
      setTimeOutDuration(timeOutDuration + 1);
      return;
    }

    const data = await loginUser({
      username,
      password,
    });

    if (data !== undefined) {
      localStorage.setItem("token", data.token);
      localStorage.setItem("userId", data.userid);
      localStorage.setItem("userLanguage", data.lang);
      localStorage.setItem("valid_until", data.valid_until);
      setToken(data.token);
      async function loadTranslations() {
        try {
          const translationsData = await fetchTranslations(data.lang);
          updateTranslations(translationsData);
        } catch (error) {
          console.error("Failed to load translations:", error);
        }
      }

      loadTranslations();

      if (data.user_complete) {
        navigate("/Rents");
      } else {
        navigate("/use");
      }
    } else {
      setMessage(translations.authentication_failed);
      setTimeOutDuration(timeOutDuration + 1);
      setToken(null);
    }
  };

  return (
    <>
      <h1>{translations.identification}</h1>
      <div className="login-wrapper">
        <div>
          <form onSubmit={handleSubmit}>
            <label>
              <p>{translations.email}</p>
              <input
                id="email"
                type="text"
                value={username}
                onChange={(e) => setUserName(e.target.value)}
              />
            </label>
            <label>
              <p>{translations.password}</p>
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </label>
            <div className="flex flexHorizontalCenter">
              <Button type="submit" variant="contained" color="primary">
                {translations.validate}
              </Button>
            </div>
          </form>
          <br></br>
          <div className="flex flexHorizontalCenter createAccountButton">
            <MuiLink component={RouterLink} to="/Signin">
              {translations.create_account}
            </MuiLink>
          </div>
        </div>
        <Message
          message={message}
          timeout={timeOutDuration}
          type={messageType}
        />
      </div>
    </>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};

export default Login;
