//dev
//export const API_URL = "http://localhost:8000/api/";
//dev avec PHP
//export const API_URL = "http://loyersws/api/";
//test en accès local au serveur VM free
//export const API_URL = "http://192.168.0.30/api/";
//test en accès local au serveur VM free distant
//export const API_URL = "http://88.172.72.107:3281/api/";
//prod LMS
export const API_URL = "https://api.magestionlocativefacile.fr/api/";

export const RENT_STATUS_PAID = "PAID";
export const RENT_STATUS_UNPAID = "UNPAID";
export const mailTitle = "Quittance de loyer";
export const mailBody =
  "Bonjour,%0D%0A%0D%0AVoici la quittance de loyer du mois de %month pour le bien situé au : %0D%0A%0D%0A %appStreet %0D%0A %appStair %0D%0A %appZipCode %appCity %0D%0A%0D%0A Cliquez sur ce lien pour télécharger la quittance : %link %0D%0A%0D%0A Cordialement,%0D%0A%0D%0A Mathias et Vincent Garand";
