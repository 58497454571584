import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { Container, Grid } from '@mui/material';

import "./css/main.css";
import Banner from "./Components/Banner";
import { TranslationProvider } from "./Components/TranslationContext";
import Appartments from "./Pages/Appartments";
import Login from "./Pages/Login";
import Signin from "./Pages/Signin";
import Logout from "./Pages/Logout";
import User from "./Pages/User";
import RecoverableCharges from "./Pages/RecoverableCharges";
import ContractCharges from "./Pages/ContractCharges";
import Rents from "./Pages/Rents";
import RentChargesAdjustment from "./Pages/RentChargesAdjustment";
import Tenants from "./Pages/Tenants";
import Contracts from "./Pages/Contracts";
import NotFound from "./Pages/NotFound";
import ContractPrices from "./Pages/ContractPrices";
import mainTheme from './Theme';

function App() {
  let [token, setToken] = useState();
  const [translations, setTranslations] = useState({});
  
  document.title = translations.app_title || 'Locabel';

  return (
    <ThemeProvider theme={mainTheme}>
      <TranslationProvider>
      <Router>
      <Container>
     <Grid container spacing={1}>
       <Grid item xs={12} sm={12}>
       <Banner></Banner>
       </Grid>
       <Grid item xs={12} sm={12}>
       <div id="container" className="mainContainer">
            <Routes>
              <Route path="/" element={<Login setToken={setToken} />} />
              <Route path="/Rents" element={<Rents />} />
              <Route path="/Appartments" element={<Appartments />} />
              <Route
                path="/ContractPrices/:contract_id"
                Component={ContractPrices}
              />
              <Route path="/Tenants" element={<Tenants />} />
              <Route path="/Contracts" element={<Contracts />} />
              <Route path="/Login" element={<Login setToken={setToken} />} />              
              <Route path="/Logout" element={<Logout setToken={setToken} />} />
              <Route path="/Signin" element={<Signin setToken={setToken} />} />
              <Route path="/User" element={<User />}/>
              <Route path="/RecoverableCharges" element={<RecoverableCharges />}/>              
              <Route path="/RentChargesAdjustment/:contract_id" element={<RentChargesAdjustment />}/>
              <Route
                path="/ContractCharges/:contract_id"
                Component={ContractCharges}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
       </Grid>
     </Grid>
   </Container>
   </Router>
   </TranslationProvider>
    </ThemeProvider>

  );
}

export default App;
