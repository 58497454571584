import { useState, useEffect, useRef, useContext } from "react";
import {
  getToken,
  postData,
  fetchDataFromAPI,
  updateData,
  deleteData,
  getGridTexts,
  useErrorHandler,
} from "../../utils/hooks";
import Login from "../Login";
import validator from "validator";
import Message from "../../Components/Message";
import ErrorMessage from "../../Components/ErrorMessage";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { Grid } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import * as constants from "../../utils/constants";

import "react-datepicker/dist/react-datepicker.css";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { TranslationContext } from "../../Components/TranslationContext";

function RecoverableCharges() {
  const { errorMessage, handleAPIError } = useErrorHandler();
  let [token, setToken] = useState();
  let [dialogMode, setDialogMode] = useState("create");
  const [message, setMessage] = useState("");
  const [uidmessage, setUidmessage] = useState(0);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [loading, setLoading] = useState(true);
  const textFieldsRefs = useRef({});
  const [data, setData] = useState([]);
  const [canDeleteCharge, setCanDeleteCharge] = useState(true);
  const [canEditCharge, setCanEditCharge] = useState(true);
  const [selectionModel, setSelectionModel] = useState([]);
  const [formValues, setFormValues] = useState({
    label: "",
  });

  const API_URL_CHARGES = `${constants.API_URL}recoverablecharge`;

  const { translations } = useContext(TranslationContext);

  const dataGridTexts = getGridTexts();
  const displayedLabels = {
    label: translations.recoverable_charges,
  };

  const [formErrors, setFormErrors] = useState({
    label: "",
  });

  const fetchApiData = async () => {
    try {
      // Fetch charges
      const fetchDataResult = await fetchDataFromAPI(
        API_URL_CHARGES,
        requestOptions,
        setData,
        true
      );
      const translatedArray = fetchDataResult.map((item) => {
        if (item.user_id === "0") {
          return {
            ...item, // Copy all attributes
            label: translations[item.label], // Translate label attribute
          };
        } else {
          return item;
        }
      });
      setData(translatedArray);
    } catch (error) {
      handleAPIError(error);
    }
    setLoading(false);
  };

  const updateMessage = (msg) => {
    setMessage(msg);
    setUidmessage(Math.random());
  };

  const handleTextFieldChange = (key) => (event) => {
    let updatedValue = event.target.value;

    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: updatedValue,
    }));
  };

  const handleSelectionChange = (newSelection) => {
    if (newSelection !== null && newSelection.length > 0) {
      const selectedData = data.filter((el) => el.id === newSelection[0]);
      setSelectionModel(newSelection);
      setCanDeleteCharge(selectedData[0].user_id !== "0");
      setCanEditCharge(selectedData[0].user_id !== "0");
    }
  };

  useEffect(() => {
    fetchApiData(); // Appel initial pour récupérer les données
  }, []);

  const resetForm = () => {
    setFormValues({
      label: "",
    });
    setFormErrors({});
    textFieldsRefs.current = {};
  };

  /**
   * Open dialog for create
   */
  const openDialogCreate = () => {
    resetForm();
    setDialogMode("create");
    setOpen(true);
  };

  /**
   * Open dialog for update
   */
  const openDialogUpdate = () => {
    if (selectionModel.length > 0) {
      const selectedItem = data.find((item) => item.id === selectionModel[0]);
      setFormValues(selectedItem);
      setDialogMode("edit");
      setOpen(true);
    }
  };

  /**
   * Open dialog for delete confirmation
   */
  const openDialogDelete = () => {
    if (selectionModel.length > 0) {
      setOpenDelete(true);
    }
  };

  /**
   * Close create/edit dialog
   */
  const handleCloseDialog = () => {
    setOpen(false);
    resetForm();
  };

  /**
   * Close confirmation delete dialog
   */
  const handleCloseDeleteDialog = () => {
    setOpenDelete(false);
  };

  /**
   * Delete selected elements and close confirmation dialog
   */
  const handleConfirmDeleteDialog = () => {
    selectionModel.forEach((element) => {
      deleteData(API_URL_CHARGES, element, getToken())
        .then((response) => {
          fetchApiData();
        })
        .then(() => {
          setMessage(translations.deletion_done);
        })
        .catch((error) => {
          handleAPIError(error);
        });
    });
    setOpenDelete(false);
  };

  const handleSaveDialog = () => {
    const formToSend = {};
    if (validateForm()) {
      formToSend.id = formValues.id;
      formToSend.label = formValues.label;
      formToSend.user_id = localStorage.getItem("userId");
      if (dialogMode === "edit") {
        updateData(API_URL_CHARGES, formToSend.id, formToSend, getToken())
          .then((response) => {
            resetForm();
            setOpen(false);
            fetchApiData();
          })
          .then(() => {
            updateMessage(translations.update_done);
          })
          .catch((error) => {
            handleAPIError(error);
          });
      } else {
        postData(API_URL_CHARGES, formToSend, getToken())
          .then((response) => {
            resetForm();
            setOpen(false);
            fetchApiData();
          })
          .then(() => {
            //setMessage(translations.update_done);
            updateMessage(translations.update_done);
          })
          .catch((error) => {
            handleAPIError(error);
          });
      }
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {
      label: "",
    };

    /**
     * All fields are mandatory, excepted dateTo
     */
    for (const [key, value] of Object.entries(formValues)) {
      if (typeof value !== "object") {
        if (
          (typeof value == "number" && value === 0) ||
          value == null ||
          validator.isEmpty(value)
        ) {
          console.log("valeur requise pour " + key);
          isValid = false;
          errors[key] = translations.required_field.replace(
            "%s",
            displayedLabels[key]
          );
        }
      }
    }

    setFormErrors(errors);
    return isValid;
  };

  const columns = [
    {
      field: "user_id",
      headerName: "",
      width: 20,
      renderCell: (params) => (params.value === "0" ? <LockIcon /> : null),
    },
    {
      field: "label",
      headerName: translations.charge,
      flex: 3,
      valueGetter: (params) => params.row.label,
    },
  ];
  const auth = `Bearer ${getToken()}`;
  const requestOptions = {
    headers: {
      Authorization: auth,
    },
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer className="toolbarContainer">
        <Button onClick={openDialogCreate} variant="outlined" color="primary">
          {translations.add}
        </Button>
        <Button
          onClick={openDialogUpdate}
          variant="outlined"
          color="primary"
          disabled={!canEditCharge}
        >
          {translations.edit}
        </Button>
        <Button
          onClick={openDialogDelete}
          variant="outlined"
          color="primary"
          disabled={!canDeleteCharge}
        >
          {translations.delete}
        </Button>
      </GridToolbarContainer>
    );
  };

  if (!getToken() || getToken() === null) {
    return <Login setToken={setToken} />;
  }
  return (
    <div>
      <h1>{translations.recoverable_charges}</h1>
      <ErrorMessage message={errorMessage} />
      <Message message={message} timestamp={{ uidmessage }} />
      {loading ? (
        <p>{translations.loading}</p>
      ) : (
        <div className="flex flexHorizontalCenter">
          <div className="dataGridContainer">
            <DataGrid
              sx={{
                color: "primary.main",
              }}
              localeText={
                dataGridTexts.components.MuiDataGrid.defaultProps.localeText
              }
              rows={data}
              columns={columns}
              editMode="cell"
              components={{
                Toolbar: CustomToolbar,
              }}
              selectionModel={selectionModel}
              onRowSelectionModelChange={(newSelectionModel) => {
                handleSelectionChange(newSelectionModel);
              }}
            />
          </div>
        </div>
      )}
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSaveDialog();
          }
        }}
      >
        <DialogTitle>
          {dialogMode === "edit"
            ? translations.update_recoverable_charge
            : translations.new_recoverable_charge}
        </DialogTitle>
        <DialogContent>
          <br></br>
          <Grid container spacing={2} direction="column">
            {Object.entries(formValues).map(([key, value]) => {
              if (key !== "id" && key !== "user_id") {
                return (
                  <Grid item key={key}>
                    <TextField
                      label={displayedLabels[key]}
                      inputRef={(ref) => (textFieldsRefs.current[key] = ref)}
                      value={value}
                      onChange={handleTextFieldChange(key)}
                      error={!!formErrors[key]}
                      helperText={formErrors[key]}
                    />
                  </Grid>
                );
              }
              return null;
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{translations.cancel}</Button>
          <Button onClick={handleSaveDialog}>{translations.save}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDelete} onClose={handleCloseDeleteDialog}>
        <DialogTitle>{translations.deletion_confirmation_title}</DialogTitle>
        <DialogContent>{translations.deletion_confirmation}</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>
            {translations.cancel}
          </Button>
          <Button onClick={handleConfirmDeleteDialog}>
            {translations.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default RecoverableCharges;
