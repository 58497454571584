import PropTypes from "prop-types";
import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import * as constants from "../../utils/constants";
import Message from "../../Components/Message";
import { TranslationContext } from "../../Components/TranslationContext";

// Fonction pour vérifier si une adresse e-mail est valide
function isEmailValid(email) {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return emailRegex.test(email);
}

async function signinUser(credentials) {
  return fetch(`${constants.API_URL}auth/signin`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
    //credentials: "omit",
  })
    .then((data) => {
      if (data.ok) {
        return data.json();
      } else {
        return data.json().then((responseData) => {
          const errorMessage =
            responseData.message || "Une erreur inconnue s'est produite.";
          const returnCode = responseData.returnCode || -1; // Valeur par défaut -1 si returnCode n'est pas défini
          return Promise.reject({ returnCode, message: errorMessage });
        });
      }
    })
    .catch((error) => {
      console.error("There was an error!", error);
      return { message: error.message, returnCode: -1 };
    });
}

function Signin({ setToken }) {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState("error");
  const [timeOutDuration, setTimeOutDuration] = useState(30000);
  const { translations } = useContext(TranslationContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const msg = searchParams.get("msg");

  useEffect(() => {
    // Vérifiez si le message est défini dans l'URL, puis affichez-le une seule fois.
    if (msg !== "" && msg !== null) {
      setMessage(msg);
    }
  }, [msg]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");

    if (!isEmailValid(username)) {
      setMessage(translations.invalid_email);
      return;
    }

    const data = await signinUser({
      username,
      password,
    });

    if (data !== undefined) {
      console.log(data.returnCode !== 0);
      if (data.returnCode !== 0) {
        setMessage(data.message);
        setMessageType("error");
        setTimeOutDuration(timeOutDuration + 1);
      }
    }
  };

  return (
    <>
      <h1>{translations.create_account}</h1>
      <div className="signin-wrapper">
        <div>
          <form onSubmit={handleSubmit}>
            <label>
              <p>{translations.email}</p>
              <input
                type="text"
                value={username}
                onChange={(e) => setUserName(e.target.value)}
              />
            </label>
            <label>
              <p>{translations.password}</p>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </label>
            <div className="flex flexHorizontalCenter">
              <Button type="submit" variant="contained" color="primary">
                {translations.validate}
              </Button>
            </div>
          </form>
        </div>
        <Message
          message={message}
          timeout={timeOutDuration}
          type={messageType}
        />
      </div>
    </>
  );
}

Signin.propTypes = {
  setToken: PropTypes.func.isRequired,
};

export default Signin;
