import React, { createContext, useContext, useState, useEffect } from "react";
import { fetchTranslations } from "../../utils/hooks";

export const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
  const [translations, setTranslations] = useState({});
  const updateTranslations = (newTranslations) => {
    setTranslations(newTranslations);
  };

  useEffect(() => {
    async function loadTranslations() {
      try {
        const userLanguage =
          localStorage.getItem("userLanguage") !== null
            ? localStorage.getItem("userLanguage")
            : navigator.language || navigator.userLanguage;
        const translationsData = await fetchTranslations(userLanguage);
        setTranslations(translationsData);
      } catch (error) {
        console.error("Failed to load translations:", error);
      }
    }
    loadTranslations();
  }, []);

  return (
    <TranslationContext.Provider value={{ translations, updateTranslations }}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslations = () => {
  return useContext(TranslationContext);
};
