import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as constants from "../../utils/constants";
import { getToken, postData } from "../../utils/hooks";
import { TranslationContext } from "../../Components/TranslationContext";

function Logout({ setToken }) {
  const API_URL = `${constants.API_URL}auth/logout`;
  const navigate = useNavigate();
  const { translations } = useContext(TranslationContext);

  useEffect(() => {
    const performLogout = async () => {
      try {
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        setToken(null);
        await postData(API_URL, null, getToken());
        navigate("/login");
      } catch (error) {
        navigate("/login");
      }
    };

    performLogout();
  }, [API_URL, navigate]);

  return <div>{translations.disconnecting}</div>;
}

export default Logout;
