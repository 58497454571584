import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getToken,
  postData,
  fetchDataFromAPI,
  updateData,
  deleteData,
  convertNumericAttributesToString,
  formatDate,
  getDateFormatSeparator,
  getGridTexts,
  useErrorHandler,
} from "../../utils/hooks";
import Login from "../Login";
import validator from "validator";
import Message from "../../Components/Message";
import ErrorMessage from "../../Components/ErrorMessage";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { Grid } from "@mui/material";
import * as constants from "../../utils/constants";
import { TranslationContext } from "../../Components/TranslationContext";
import { useRef, useContext } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fr, it, enGB, pt, de, nl, es } from "date-fns/locale/";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";

function ContractPrices() {
  const { errorMessage, handleAPIError } = useErrorHandler();
  let [token, setToken] = useState();
  const [dateFrom, setDateFrom] = useState(new Date());
  let [dialogMode, setDialogMode] = useState("create");
  const [calendarDialogClassName, setCalendarDialogClassName] = useState("");
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  const [canEditOrDeletePrice, setCanEditOrDeletePrice] = useState(false);
  const [lastRent, setLastRent] = useState([]);
  const [minDateFrom, setMinDateFrom] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [loading, setLoading] = useState(true);
  const textFieldsRefs = useRef({});
  const [data, setData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [allCurrencies, setAllCurrencies] = useState([]);
  const [formValues, setFormValues] = useState({
    price: "",
    ccy: "EUR",
    dateFrom: new Date(),
  });
  const { translations } = useContext(TranslationContext);
  registerLocale("fr", fr);
  registerLocale("it", it);
  registerLocale("en", enGB);
  registerLocale("pt", pt);
  registerLocale("de", de);
  registerLocale("es", es);
  registerLocale("nl", nl);

  const dataGridTexts = getGridTexts();

  const displayedLabels = {
    dateFrom: translations.from_date,
    price: translations.price,
  };

  const [formErrors, setFormErrors] = useState({
    dateFrom: "",
    price: "",
    ccy: "",
  });

  const { contract_id } = useParams();
  const fetchApiData = async () => {
    // Read contracts prices
    await fetchDataFromAPI(API_URL_READ, requestOptions, setData, true)
      .then(() => {
        // Fetch all currencies
        fetchDataFromAPI(
          API_URL_CURRENCIES,
          requestOptions,
          setAllCurrencies,
          false
        )
          .then(() => {
            fetchDataFromAPI(
              API_URL_LAST_RENT + contract_id,
              requestOptions,
              setLastRent,
              false
            );
          })
          .catch((error) => {
            handleAPIError(error);
          });
      })
      .catch((error) => {
        handleAPIError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchApiData(); // Appel initial pour récupérer les données
  }, []);

  const resetForm = () => {
    setFormValues({
      price: "",
      ccy: "EUR",
      dateFrom: new Date(),
    });
    setFormErrors({});
    textFieldsRefs.current = {};
  };

  /**
   * Open dialog for create
   */
  const openDialogCreate = async () => {
    try {
      let minDate = new Date();
      if (lastRent !== null) {
        if (typeof lastRent.lastRent === "object") {
          minDate = new Date(lastRent.lastRent.date);
          minDate.setDate(minDate.getDate() + 1);
        }
        //if there is no rent yet get contract date as new price minimum date
        else {
          if (typeof lastRent.contract === "object") {
            minDate = new Date(lastRent.contract.dateFrom);
          }
        }
        setMinDateFrom(minDate);
        setDateFrom(minDate);
      }

      setDialogMode("create");
      setOpen(true);
    } catch (error) {
      handleAPIError(error);
    }
  };

  /**
   * Open dialog for update
   */
  const openDialogUpdate = () => {
    if (selectionModel.length > 0) {
      const selectedItem = data.find((item) => item.id === selectionModel[0]);
      const convertedObj = convertNumericAttributesToString(selectedItem);
      setFormValues(convertedObj);
      setDialogMode("edit");
      setOpen(true);
    }
  };

  /**
   * Open dialog for delete confirmation
   */
  const openDialogDelete = () => {
    if (selectionModel.length > 0) {
      setOpenDelete(true);
    }
  };

  const handleTextFieldChange = (key) => (event) => {
    let updatedValue = event.target.value;

    //accept numbers and date separator for dates
    if (key.substring(0, 4) === "date") {
      const forbiddenCharacter = getDateFormatSeparator();
      // Remplacer tous les caractères non numériques par une chaîne vide
      const pattern = new RegExp(`[^0-9${forbiddenCharacter}]+`, "g");
      updatedValue = updatedValue.replace(pattern, "");
    }

    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: updatedValue,
    }));
  };

  const handleCalendarEditClose = () => {
    setCalendarDialogClassName("");
  };

  const handleCalendarEditOpen = () => {
    setCalendarDialogClassName("priceEditDialog");
  };

  /**
   * Close create/edit dialog
   */
  const handleCloseDialog = () => {
    setOpen(false);
    resetForm();
  };

  /**
   * Close confirmation delete dialog
   */
  const handleCloseDeleteDialog = () => {
    setOpenDelete(false);
  };

  /**
   * Delete selected elements and close confirmation dialog
   */
  const handleConfirmDeleteDialog = () => {
    selectionModel.forEach((element) => {
      deleteData(API_URL, element, getToken())
        .then((response) => {
          fetchApiData();
        })
        .then(() => {
          setMessage(translations.deletion_done);
        })
        .catch((error) => {
          handleAPIError(error);
        });
    });
    setOpenDelete(false);
  };

  const API_URL_READ = `${constants.API_URL}price/${contract_id}`;
  const API_URL = `${constants.API_URL}price`;
  const API_URL_CURRENCIES = `${constants.API_URL}currency`;
  const API_URL_LAST_RENT = `${constants.API_URL}rent/last/`;

  const handleDateFromChange = (dt) => {
    setDateFrom(dt);
    setFormValues((prevValues) => ({
      ...prevValues,
      dateFrom: dt,
    }));
  };

  const handleSelectionChange = (newSelection) => {
    if (newSelection !== null && newSelection.length > 0) {
      const selectedData = data.filter((el) => el.id === newSelection[0]);
      setSelectionModel(newSelection);
      const priceDate = new Date(selectedData[0].dateFrom);

      if (lastRent !== null) {
        let lastRentDate = new Date(lastRent.date);
        if (priceDate > lastRentDate) {
          setCanEditOrDeletePrice(true);
        } else {
          setCanEditOrDeletePrice(false);
        }
      } else {
        setCanEditOrDeletePrice(true);
      }
    }
  };

  const handleSaveDialog = () => {
    if (validateForm()) {
      formValues.contract_id = contract_id;
      formValues.user_id = localStorage.getItem("userId");
      const formToSend = {
        ...formValues,
      };
      formToSend.dateFrom = dateFrom;
      formToSend.ccy = formValues.ccy;
      if (dialogMode === "edit") {
        updateData(API_URL, formToSend.id, formToSend, getToken())
          .then((response) => {
            resetForm();
            setOpen(false);
            fetchApiData();
          })
          .then(() => {
            setMessage(translations.update_done);
          })
          .catch((error) => {
            handleAPIError(error);
          });
      } else {
        postData(API_URL, formToSend, getToken())
          .then((response) => {
            resetForm();
            setOpen(false);
            fetchApiData();
          })
          .then(() => {
            setMessage(translations.update_done);
          })
          .catch((error) => {
            handleAPIError(error);
          });
      }
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {
      dateFrom: "",
      price: "",
    };

    /**
     * All fields are mandatory
     */
    for (const [key, value] of Object.entries(formValues)) {
      if (typeof value !== "object") {
        if (value == null || validator.isEmpty(value)) {
          isValid = false;
          errors[key] = translations.required_field.replace(
            "%s",
            displayedLabels[key]
          );
        }
      }
    }

    setFormErrors(errors);
    return isValid;
  };

  const columns = [
    {
      field: "dateFrom",
      headerName: translations.from_date,
      flex: 1,
      valueFormatter: formatDate,
      editable: true,
      //headerClassName: "defaultFont",
    },
    {
      field: "price",
      headerName: translations.price,
      flex: 1,
      editable: true,
      align: "right",
      headerAlign: "right",
    },
    {
      field: "ccy",
      headerName: translations.currency,
      flex: 1,
      editable: true,
    },
  ];
  const auth = `Bearer ${getToken()}`;
  const requestOptions = {
    headers: {
      Authorization: auth,
    },
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer className="toolbarContainer">
        <Button onClick={openDialogCreate} variant="outlined" color="primary">
          {translations.add}
        </Button>
        <Button
          onClick={openDialogUpdate}
          variant="outlined"
          color="primary"
          disabled={!canEditOrDeletePrice}
        >
          {translations.edit}
        </Button>
        <Button
          onClick={openDialogDelete}
          variant="outlined"
          color="primary"
          disabled={!canEditOrDeletePrice}
        >
          {translations.delete}
        </Button>
      </GridToolbarContainer>
    );
  };

  if (!getToken() || getToken() === null) {
    return <Login setToken={setToken} />;
  }
  return (
    <div>
      <h1>{translations.rent_amount}</h1>
      <ErrorMessage message={errorMessage} />
      <Message message={message} />
      {loading ? (
        <p>{translations.loading}</p>
      ) : error ? (
        error.response && error.response.status === 401 ? (
          <Login setToken={setToken} />
        ) : (
          <p>Error: {error.message}</p>
        )
      ) : (
        <div className="flex flexHorizontalCenter">
          <div className="dataGridContainer">
            <DataGrid
              sx={{
                color: "primary.main",
              }}
              localeText={
                dataGridTexts.components.MuiDataGrid.defaultProps.localeText
              }
              rows={data}
              columns={columns}
              //getRowClassName={getRowClassName}
              editMode="cell"
              components={{
                Toolbar: CustomToolbar,
              }}
              selectionModel={selectionModel}
              onRowSelectionModelChange={(newSelectionModel) => {
                handleSelectionChange(newSelectionModel);
              }}
            />
          </div>
        </div>
      )}
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSaveDialog();
          }
        }}
      >
        <DialogTitle>
          {dialogMode === "edit"
            ? translations.update_rent_amount
            : translations.add_rent_amount}
        </DialogTitle>
        <DialogContent className={calendarDialogClassName}>
          <br></br>
          <Grid container spacing={2} direction="column">
            {Object.entries(formValues).map(([key, value]) => {
              if (key !== "id" && key !== "user_id" && key !== "contract_id") {
                if (key === "ccy") {
                  return (
                    <Grid item key={key}>
                      <InputLabel id="select-label-ccy">
                        {translations.currency}
                      </InputLabel>
                      <Select
                        labelId="select-label-ccy"
                        id="select-ccy"
                        value={value}
                        label={translations.currency}
                        onChange={handleTextFieldChange(key)}
                        error={!!formErrors[key]}
                        style={{ width: "100%" }}
                      >
                        {allCurrencies.map((ccy, index) => (
                          <MenuItem key={ccy} value={ccy}>
                            {ccy}&nbsp;{translations["currency_" + ccy]}
                          </MenuItem>
                        ))}
                      </Select>
                      {formErrors[key] && (
                        <FormHelperText error>{formErrors[key]}</FormHelperText>
                      )}
                    </Grid>
                  );
                } else if (key === "dateFrom") {
                  return (
                    <Grid item key={key}>
                      <InputLabel id="select-label-dateFrom">
                        {translations.from_date}
                      </InputLabel>
                      <div onKeyDown={(e) => e.preventDefault()}>
                        <DatePicker
                          showIcon
                          selected={dateFrom}
                          minDate={minDateFrom}
                          onChange={(date) => handleDateFromChange(date)}
                          locale={localStorage
                            .getItem("userLanguage")
                            .substring(0, 2)}
                          className="datePicker"
                          dateFormat="P"
                          onCalendarClose={handleCalendarEditClose}
                          onCalendarOpen={handleCalendarEditOpen}
                        />
                      </div>
                    </Grid>
                  );
                } else
                  return (
                    <Grid item key={key}>
                      <TextField
                        label={displayedLabels[key]}
                        inputRef={(ref) => (textFieldsRefs.current[key] = ref)}
                        value={value}
                        onChange={handleTextFieldChange(key)}
                        error={!!formErrors[key]}
                        helperText={formErrors[key]}
                      />
                    </Grid>
                  );
              }
              return null;
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{translations.cancel}</Button>
          <Button onClick={handleSaveDialog}>{translations.save}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDelete} onClose={handleCloseDeleteDialog}>
        <DialogTitle>{translations.deletion_confirmation_title}</DialogTitle>
        <DialogContent>{translations.deletion_confirmation}</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>
            {translations.cancel}
          </Button>
          <Button onClick={handleConfirmDeleteDialog}>
            {translations.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ContractPrices;
