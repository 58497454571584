import { useState, useEffect, useRef, useContext } from "react";
import {
  getToken,
  postData,
  fetchDataFromAPI,
  updateData,
  getLocaleCountryName,
  deleteData,
  convertNumericAttributesToString,
  isValidEmail,
  getGridTexts,
  useErrorHandler,
  isValidZipCode,
} from "../../utils/hooks";
import Message from "../../Components/Message";
import ErrorMessage from "../../Components/ErrorMessage";
import Login from "../Login";
import validator from "validator";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { Grid } from "@mui/material";
import * as constants from "../../utils/constants";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { TranslationContext } from "../../Components/TranslationContext";

function Tenants() {
  const { errorMessage, handleAPIError } = useErrorHandler();
  let [token, setToken] = useState();
  let [dialogMode, setDialogMode] = useState("create");
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [allCountries, setAllCountries] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [loading, setLoading] = useState(true);
  const textFieldsRefs = useRef({});
  const [data, setData] = useState([]);
  const [canDo, setCanDo] = useState({
    canDelete: false,
    canUpdate: false,
  });
  const [message, setMessage] = useState(null);
  const [selectionModel, setSelectionModel] = useState([]);
  const { translations } = useContext(TranslationContext);
  const [formValues, setFormValues] = useState({
    name: "",
    street: "",
    zipcode: "",
    city: "",
    country: "",
    email: "",
  });
  const displayedLabels = {
    name: "Nom",
    street: "Rue",
    zipcode: "CP",
    city: "Ville",
    country: "Pays",
    email: "Email",
  };

  const [formErrors, setFormErrors] = useState({
    name: "",
    street: "",
  });

  const dataGridTexts = getGridTexts();

  const fetchApiData = async () => {
    try {
      await fetchDataFromAPI(API_URL, requestOptions, setData, true);
      //read available countries
      fetchDataFromAPI(
        API_URL_COUNTRIES,
        requestOptions,
        setAllCountries,
        false
      );
      setLoading(false);
    } catch (error) {
      handleAPIError(error);
    }
  };

  const fetchApiCanDo = async (id) => {
    const URL = API_URL_CAN_DO + id;
    fetchDataFromAPI(URL, requestOptions, setCanDo, false);
  };

  useEffect(() => {
    fetchApiData(); // Appel initial pour récupérer les données
  }, []);

  const resetForm = () => {
    setFormValues({
      name: "",
      street: "",
      zipcode: "",
      city: "",
      country: "",
      email: "",
    });
    setFormErrors({});
    textFieldsRefs.current = {};
  };

  const handleSelectionChange = (newSelection) => {
    if (newSelection !== null && newSelection.length > 0) {
      setSelectionModel(newSelection);
      fetchApiCanDo(newSelection[0]);
    }
  };

  /**
   * Open dialog for create
   */
  const openDialogCreate = () => {
    setDialogMode("create");
    setOpen(true);
  };

  /**
   * Open dialog for update
   */
  const openDialogUpdate = () => {
    if (selectionModel.length > 0) {
      const selectedItem = data.find((item) => item.id === selectionModel[0]);
      console.log(selectedItem);
      const convertedObj = convertNumericAttributesToString(selectedItem);
      console.log(selectedItem);
      setFormValues(convertedObj);
      setDialogMode("edit");
      setOpen(true);
    }
  };

  /**
   * Open dialog for delete confirmation
   */
  const openDialogDelete = () => {
    if (selectionModel.length > 0) {
      setOpenDelete(true);
    }
  };

  const handleTextFieldChange = (key) => (event) => {
    let updatedValue = event.target.value;

    // For zipcode
    if (key === "zipcode") {
      // replace all non-numeric characters by nothing
      updatedValue = updatedValue.replace(/\D/g, "");
    }

    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: updatedValue,
    }));
  };

  /**
   * Close create/edit dialog
   */
  const handleCloseDialog = () => {
    /*
    const values = Object.entries(textFieldsRefs.current).reduce(
      (acc, [key, ref]) => {
        acc[key] = ref.value;
        return acc;
      },
      {}
    );
    */
    setOpen(false);
    resetForm();
  };

  /**
   * Close confirmation delete dialog
   */
  const handleCloseDeleteDialog = () => {
    setOpenDelete(false);
  };

  /**
   * Delete selected elements and close confirmation dialog
   */
  const handleConfirmDeleteDialog = () => {
    selectionModel.forEach((element) => {
      deleteData(API_URL, element, getToken())
        .then((response) => {
          fetchApiData();
        })
        .then(() => {
          setMessage(translations.deletion_done);
        })
        .catch((error) => {
          handleAPIError(error);
        });
    });

    setOpenDelete(false);
  };

  /**
   * Function called when user select a country on create/update
   * @param {event} event
   */
  const handleSelectCountry = (event) => {
    const selectedCountry = event.target.value;
    setFormValues((prevValues) => ({
      ...prevValues,
      country: selectedCountry,
    }));
  };

  const API_URL = `${constants.API_URL}tenant`;
  const API_URL_CAN_DO = `${constants.API_URL}tenant/cando/`;
  const API_URL_COUNTRIES = `${constants.API_URL}country`;

  const handleSaveDialog = () => {
    if (validateForm()) {
      formValues.user_id = localStorage.getItem("userId");
      if (dialogMode === "edit") {
        updateData(API_URL, formValues.id, formValues, getToken())
          .then((response) => {
            fetchApiData();
          })
          .then(() => {
            setMessage(translations.update_done);
          })
          .catch((error) => {
            handleAPIError(error);
          });
      } else {
        postData(API_URL, formValues, getToken())
          .then((response) => {
            fetchApiData();
          })
          .then(() => {
            setMessage(translations.update_done);
          })
          .catch((error) => {
            handleAPIError(error);
          });
      }
      resetForm();
      setOpen(false);
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {
      name: "",
      street: "",
      zipcode: "",
      city: "",
      country: "",
      email: "",
    };

    /**
     * All fields are mandatory
     */
    for (const [key, value] of Object.entries(formValues)) {
      if (value == null || validator.isEmpty(value)) {
        isValid = false;
        errors[key] = translations.required_field.replace(
          "%s",
          displayedLabels[key]
        );
      } else if (key === "email") {
        if (!isValidEmail(value)) {
          isValid = false;
          errors[key] = translations.invalid_email;
        }
      } else if (!isValidZipCode(formValues.country, formValues.zipcode)) {
        errors["zipcode"] = translations.invalid_zipcode;
        isValid = false;
      }
    }

    setFormErrors(errors);
    return isValid;
  };

  const columns = [
    {
      field: "name",
      headerName: translations.last_name,
      flex: 3,
      editable: true,
      //headerClassName: "defaultFont",
    },
    {
      field: "street",
      headerName: translations.street,
      flex: 4,
      editable: true,
      align: "left",
      headerAlign: "left",
      //headerClassName: "defaultFont",
    },
    {
      field: "city",
      headerName: translations.city,
      flex: 2,
      editable: true,
      //headerClassName: "defaultFont",
    },
    {
      field: "zipcode",
      headerName: translations.zipcode,
      flex: 1,
      editable: true,
      //headerClassName: "defaultFont",
    },
    {
      field: "country",
      headerName: translations.country,
      flex: 2,
      editable: true,
      valueFormatter: (params) => getLocaleCountryName(translations, params),
      //headerClassName: "defaultFont",
    },
    {
      field: "email",
      headerName: translations.email,
      flex: 3,
      type: String,
      editable: true,
      //headerClassName: "defaultFont",
    },
  ];
  const auth = `Bearer ${getToken()}`;
  const requestOptions = {
    headers: {
      Authorization: auth,
    },
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer className="toolbarContainer">
        <Button onClick={openDialogCreate} variant="outlined" color="primary">
          {translations.add}
        </Button>
        <Button
          onClick={openDialogUpdate}
          variant="outlined"
          color="primary"
          disabled={!canDo.canUpdate}
        >
          {translations.edit}
        </Button>
        <Button
          onClick={openDialogDelete}
          variant="outlined"
          color="primary"
          disabled={!canDo.canDelete}
        >
          {translations.delete}
        </Button>
      </GridToolbarContainer>
    );
  };

  if (!getToken() || getToken() === null) {
    return <Login setToken={setToken} />;
  }
  return (
    <div>
      <h1>{translations.tenants}</h1>
      <ErrorMessage message={errorMessage} />
      <Message message={message} />
      {loading ? (
        <p>{translations.loading}</p>
      ) : error ? (
        error.response && error.response.status === 401 ? (
          <Login setToken={setToken} />
        ) : (
          <p>Error: {error.message}</p>
        )
      ) : (
        <div className="flex flexHorizontalCenter">
          <div className="dataGridContainer">
            <DataGrid
              sx={{
                color: "primary.main",
              }}
              localeText={
                dataGridTexts.components.MuiDataGrid.defaultProps.localeText
              }
              rows={data}
              columns={columns}
              //getRowClassName={getRowClassName}
              editMode="cell"
              components={{
                Toolbar: CustomToolbar,
              }}
              onRowSelectionModelChange={(newSelectionModel) => {
                handleSelectionChange(newSelectionModel);
              }}
            />
          </div>
        </div>
      )}
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>
          {dialogMode === "edit"
            ? "Modifier le locataire"
            : "Nouveau locataire"}
        </DialogTitle>
        <DialogContent>
          <br></br>
          <Grid container spacing={2} direction="column">
            {Object.entries(formValues).map(([key, value]) => {
              if (key !== "id" && key !== "user_id" && key[0] !== "_") {
                //country
                if (key === "country") {
                  return (
                    <Grid item key={key}>
                      <InputLabel id="select-label-country">
                        {translations.country}
                      </InputLabel>
                      <Select
                        labelId="select-label-country"
                        id="select-country"
                        value={formValues.country}
                        label={translations.country}
                        onChange={handleSelectCountry}
                        style={{ width: "100%" }}
                      >
                        {allCountries.map((country, index) => (
                          <MenuItem key={country} value={country}>
                            {translations["country_" + country.toLowerCase()]}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  );
                }
                //id and inner mongoose attributes must be hidden
                else
                  return (
                    <Grid item key={key}>
                      <TextField
                        //label={displayedLabels[key]}
                        label={displayedLabels[key]}
                        inputRef={(ref) => (textFieldsRefs.current[key] = ref)}
                        value={value}
                        onChange={handleTextFieldChange(key)}
                        error={!!formErrors[key]}
                        helperText={formErrors[key]}
                      />
                    </Grid>
                  );
              }
              return null;
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{translations.cancel}</Button>
          <Button onClick={handleSaveDialog}>{translations.save}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDelete} onClose={handleCloseDeleteDialog}>
        <DialogTitle>{translations.deletion_confirmation_title}</DialogTitle>
        <DialogContent>{translations.deletion_confirmation}</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>
            {translations.cancel}
          </Button>
          <Button onClick={handleConfirmDeleteDialog}>
            {translations.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Tenants;
