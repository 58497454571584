import React, { useState, useEffect } from "react";

const ErrorMessage = ({ message, timeout = 10000 }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [key, setKey] = useState(0);

  useEffect(() => {
    let timeoutId;

    if (message) {
      setIsVisible(true);
      timeoutId = setTimeout(() => {
        setIsVisible(false);
      }, timeout);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [message, timeout]);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setKey((prevKey) => prevKey + 1);
      }, 10);
    }
  }, [message, timeout]);

  return isVisible ? (
    <div key={key} className={"fade-animation error-message"}>
      <span>{message}</span>
    </div>
  ) : null;
};

export default ErrorMessage;
