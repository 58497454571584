import { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  getToken,
  postData,
  fetchDataFromAPI,
  updateData,
  deleteData,
  getDateFormatSeparator,
  formatDate,
  getGridTexts,
  useErrorHandler,
  getUserLang,
  translate,
} from "../../utils/hooks";
import Login from "../Login";
import validator from "validator";
import Message from "../../Components/Message";
import ErrorMessage from "../../Components/ErrorMessage";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { Grid } from "@mui/material";
import * as constants from "../../utils/constants";
import DatePicker, { registerLocale } from "react-datepicker";
import { fr, it, enGB, pt, de, nl, es } from "date-fns/locale/";

import "react-datepicker/dist/react-datepicker.css";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { TranslationContext } from "../../Components/TranslationContext";

function Contracts() {
  const { errorMessage, handleAPIError } = useErrorHandler();
  let [token, setToken] = useState();
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [minDateTo, setMinDateTo] = useState(new Date());
  const [calendarDialogClassName, setCalendarDialogClassName] = useState("");
  const [languages, setLanguages] = useState([]);
  let [dialogMode, setDialogMode] = useState("create");
  const [message, setMessage] = useState("");
  const [uidmessage, setUidmessage] = useState(0);
  const [open, setOpen] = useState(false);
  const [openEnd, setOpenEnd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [loading, setLoading] = useState(true);
  const textFieldsRefs = useRef({});
  const textFieldsEndRefs = useRef({});
  const [data, setData] = useState([]);
  const [lastRent, setLastRent] = useState([]);
  const [canDo, setCanDo] = useState({
    canDelete: false,
    canUpdate: false,
  });
  const [canEndContract, setCanEndContract] = useState(false);
  //const [canDeleteContract, setCanDeleteContract] = useState(false);
  const [allAppartments, SetAllAppartments] = useState([]);
  const [allTenants, setAllTenants] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const navigate = useNavigate();
  const { addWeeks, addMonths } = require("date-fns");
  const [formValues, setFormValues] = useState({
    appartment: "",
    tenant: "",
    dateFrom: new Date(),
    dateTo: "",
    lang: getUserLang(),
    frequency: "MONTH",
  });

  const [formEndValues, setFormEndValues] = useState({
    dateTo: "",
  });

  const frequencies = ["WEEK", "BIWEEK", "MONTH"];

  const { translations } = useContext(TranslationContext);
  registerLocale("fr", fr);
  registerLocale("it", it);
  registerLocale("en", enGB);
  registerLocale("pt", pt);
  registerLocale("de", de);
  registerLocale("es", es);
  registerLocale("nl", nl);

  const dataGridTexts = getGridTexts();
  const displayedLabels = {
    appartment: translations.rent_property,
    tenant: translations.tenant,
    dateFrom: translations.from_date,
    dateTo: translations.to_date,
    lang: translations.language_documents,
    frequency: translations.frequency_payment,
  };

  const [formErrors, setFormErrors] = useState({
    appartment: "",
    tenant: "",
    dateFrom: "",
    dateTo: "",
    lang: "",
    frequency: "",
  });

  const [formEndErrors, setFormEndErrors] = useState({
    dateTo: "",
  });

  const fetchApiData = async () => {
    try {
      // Fetch contracts
      await fetchDataFromAPI(API_URL_CONTRACTS, requestOptions, setData, true)
        .then(() => {
          // Fetch available appartments
          fetchDataFromAPI(
            API_URL_ALL_APPARTMENTS,
            requestOptions,
            SetAllAppartments,
            true
          )
            .then(() => {
              // Fetch tenants
              fetchDataFromAPI(
                API_URL_TENANTS,
                requestOptions,
                setAllTenants,
                true
              );
            })
            .catch((error) => {
              handleAPIError(error);
            });
        })
        .catch((error) => {
          handleAPIError(error);
        });
    } catch (error) {
      handleAPIError(error);
    }
    setLoading(false);
  };

  const fetchApiCanDo = async (id) => {
    const URL = API_URL_CAN_DO + id;
    fetchDataFromAPI(URL, requestOptions, setCanDo, false);
  };

  const fetchLanguages = () => {
    fetchDataFromAPI(
      API_URL_LANGUAGES,
      requestOptions,
      setLanguages,
      false
    ).catch((error) => {
      handleAPIError(error);
    });
  };

  const getMinNewContractFromDate = () => {
    // Filtrer les objets avec une dateTo non vide
    const nonEmptyDates = data.filter(
      (item) => item.dateTo && item.dateTo !== "0000-00-00"
    );
    if (nonEmptyDates.length > 0) {
      // Trouver la date la plus récente parmi les dates non vides
      const mostRecentDate = nonEmptyDates.reduce((latestDate, currentItem) => {
        if (!latestDate) return currentItem; // Si c'est la première date non vide

        const currentDate = new Date(currentItem.dateTo);
        const latest = new Date(latestDate.dateTo);

        return currentDate > latest ? currentItem : latestDate;
      }, null);

      let ret = new Date(mostRecentDate.dateTo);
      ret.setDate(ret.getDate() + 1);
      return ret;
    } else {
      return new Date();
    }
  };

  const updateMessage = (msg) => {
    setMessage(msg);
    setUidmessage(Math.random());
  };

  const handleTextFieldChange = (key) => (event) => {
    let updatedValue = event.target.value;

    //accept numbers and date separator for dates
    if (key.substring(0, 4) === "date") {
      const forbiddenCharacter = getDateFormatSeparator();
      // Remplacer tous les caractères non numériques par une chaîne vide
      const pattern = new RegExp(`[^0-9${forbiddenCharacter}]+`, "g");
      updatedValue = updatedValue.replace(pattern, "");
    }

    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: updatedValue,
    }));
  };

  const handleCalendarEditClose = () => {
    setCalendarDialogClassName("");
  };

  const handleCalendarEditOpen = () => {
    setCalendarDialogClassName("contractEditDialog");
  };

  const handleCalendarEndClose = () => {
    setCalendarDialogClassName("");
  };

  const handleCalendarEndOpen = () => {
    setCalendarDialogClassName("contractEndDialog");
  };

  const handleSelectionChange = (newSelection) => {
    if (newSelection !== null && newSelection.length > 0) {
      setSelectionModel(newSelection);
      fetchApiCanDo(newSelection[0]);
      const selectedData = data.filter((el) => el.id === newSelection[0]);
      const emptyDate = selectedData[0].dateTo.trim().length === 0;
      setCanEndContract(emptyDate);
    }
  };

  /*
  const handleSelectionChange = (newSelection) => {
    if (newSelection !== null && newSelection.length > 0) {
      const selectedData = data.filter((el) => el.id === newSelection[0]);
      const emptyDate = selectedData[0].dateTo.trim().length === 0;
      setSelectionModel(newSelection);
      if (emptyDate) {
        setCanEndContract(true);
        setCanDeleteContract(true);
        //if at list one rent exists, disable delete contract
        const dtFrom = new Date(selectedData[0].dateFrom);
        const today = new Date();
        const maxDate =
          selectedData[0].frequency === "MONTH"
            ? addMonths(dtFrom, 1)
            : addWeeks(dtFrom, selectedData[0].frequency === "WEEK" ? 1 : 2);
        if (maxDate >= today) {
          setCanDeleteContract(true);
        } else {
          setCanDeleteContract(false);
        }
      } else {
        setCanEndContract(false);
        setCanDeleteContract(false);
      }
    } else {
      setCanEndContract(true);
      setCanDeleteContract(true);
    }
  };
*/

  const handleDateFromChange = (dt) => {
    setDateFrom(dt);
    setFormValues((prevValues) => ({
      ...prevValues,
      dateFrom: dt,
    }));
  };

  const handleDateToChange = (dt) => {
    setDateTo(dt);
    setFormValues((prevValues) => ({
      ...prevValues,
      dateTo: dt,
    }));
  };

  useEffect(() => {
    fetchApiData(); // Get data
    fetchLanguages();
  }, []);

  const resetForm = () => {
    setFormValues({
      appartment: "",
      tenant: "",
      dateFrom: new Date(),
      dateTo: "",
      lang: getUserLang(),
      frequency: "MONTH",
    });
    setFormErrors({});
    textFieldsRefs.current = {};
  };

  const resetEndForm = () => {
    setFormEndValues({
      dateTo: "",
    });
    setFormEndErrors({});
    textFieldsEndRefs.current = {};
  };

  /**
   * navigate to given page
   */
  const gotoPage = (page) => {
    if (selectionModel.length > 0) {
      navigate(`/${page}/${selectionModel[0]}`);
    }
  };

  /**
   * navigate to appartment prices
   */
  const gotoContractPricesPage = () => {
    gotoPage("ContractPrices");
  };

  /**
   * navigate to contract charges
   */
  const gotoContractChargesPage = () => {
    gotoPage("ContractCharges");
  };

  /**
   * Open dialog for create
   */
  const openDialogCreate = () => {
    if (allAppartments.length > 0) {
      setDateFrom(getMinNewContractFromDate());
      setDialogMode("create");
      setOpen(true);
    }
  };

  /**
   * Open dialog for delete confirmation
   */
  const openDialogDelete = () => {
    if (selectionModel.length > 0) {
      setOpenDelete(true);
    }
  };

  /**
   * Open dialog for contract end
   */
  const openDialogEnd = () => {
    if (selectionModel.length > 0) {
      const selectedData = data.filter((el) => el.id === selectionModel[0]);
      fetchDataFromAPI(
        API_URL_LAST_RENT + selectedData[0].id,
        requestOptions,
        setLastRent,
        false
      )
        .then(() => {
          let minDate = new Date(
            lastRent.length > 0 ? lastRent.date : selectedData[0].dateFrom
          );
          minDate.setDate(minDate.getDate() + 1);
          setMinDateTo(minDate);
          setDateTo(minDate);
          setOpenEnd(true);
        })
        .catch((error) => {
          handleAPIError(error);
        });
    }
  };

  /**
   * Close create/edit dialog
   */
  const handleCloseDialog = () => {
    setOpen(false);
    resetForm();
  };

  /**
   * Close create/edit dialog
   */
  const handleCloseEndDialog = () => {
    setOpenEnd(false);
    resetEndForm();
  };

  /**
   * Close confirmation delete dialog
   */
  const handleCloseDeleteDialog = () => {
    setOpenDelete(false);
  };

  /**
   * Delete selected elements and close confirmation dialog
   */
  const handleConfirmDeleteDialog = () => {
    selectionModel.forEach((element) => {
      deleteData(API_URL_CONTRACTS, element, getToken())
        .then((response) => {
          fetchApiData();
        })
        .then(() => {
          setMessage(translations.deletion_done);
        })
        .catch((error) => {
          handleAPIError(error);
        });
    });
    setOpenDelete(false);
  };

  /**
   * Update selected elements and close confirmation dialog
   */
  const handleConfirmEndDialog = () => {
    if (validateEndForm()) {
      // TODO N'autoriser que le premier élément sélectionné
      const formToSend = {};
      formToSend.user_id = localStorage.getItem("userId");
      selectionModel.forEach((element) => {
        //get contract
        const selectedData = data.filter((el) => el.id === element);
        if (selectedData.length > 0) {
          formToSend.id = element;
          formToSend.dateTo = dateTo;
          updateData(API_URL_CONTRACTS, element, formToSend, getToken())
            .then((response) => {
              fetchApiData();
            })
            .then(() => {
              console.log("Action terminée");
              //setMessage(translations.update_done);
              updateMessage(translations.update_done);
            })
            .catch((error) => {
              handleAPIError(error);
            });
        }
      });
      resetEndForm();
      setOpenEnd(false);
    }
  };

  const API_URL_CONTRACTS = `${constants.API_URL}contract`;
  const API_URL_LAST_RENT = `${constants.API_URL}rent/last/`;
  const API_URL_TENANTS = `${constants.API_URL}tenant`;
  const API_URL_CAN_DO = `${constants.API_URL}contract/cando/`;
  const API_URL_ALL_APPARTMENTS = `${constants.API_URL}appartment/available`;
  const API_URL_LANGUAGES = `${constants.API_URL}languages`;

  const handleSaveDialog = () => {
    const formToSend = {};
    if (validateForm()) {
      formToSend.appartment_id = formValues.appartment;
      formToSend.tenant_id = formValues.tenant;
      formToSend.lang = formValues.lang;
      formToSend.dateFrom = dateFrom;
      formToSend.user_id = localStorage.getItem("userId");
      formToSend.frequency = formValues.frequency;
      if (dialogMode === "edit") {
        updateData(API_URL_CONTRACTS, formToSend.id, formToSend, getToken())
          .then((response) => {
            resetForm();
            setOpen(false);
            fetchApiData();
          })
          .then(() => {
            console.log("Ajout terminé");
            //setMessage(translations.update_done);
            updateMessage(translations.update_done);
          })
          .catch((error) => {
            handleAPIError(error);
          });
      } else {
        postData(API_URL_CONTRACTS, formToSend, getToken())
          .then((response) => {
            resetForm();
            setOpen(false);
            fetchApiData();
          })
          .then(() => {
            //setMessage(translations.update_done);
            updateMessage(translations.update_done);
          })
          .catch((error) => {
            handleAPIError(error);
          });
      }
    }
  };

  const validateForm = () => {
    console.log("validate form");
    let isValid = true;
    const errors = {
      date: "",
      price: "",
    };

    /**
     * All fields are mandatory, excepted dateTo
     */
    for (const [key, value] of Object.entries(formValues)) {
      if (typeof value !== "object") {
        console.log("check " + key + " : " + value);
        if (key !== "dateTo") {
          if (
            (typeof value == "number" && value === 0) ||
            value == null ||
            validator.isEmpty(value)
          ) {
            console.log("valeur requise pour " + key);
            isValid = false;
            errors[key] = translations.required_field.replace(
              "%s",
              displayedLabels[key]
            );
          }
        }
      }
    }

    setFormErrors(errors);
    return isValid;
  };

  const validateEndForm = () => {
    let isValid = true;
    const errors = {
      dateTo: "",
    };

    /**
     * All fields are mandatory, excepted dateTo
     */
    for (const [key, value] of Object.entries(formEndValues)) {
      if (typeof value !== "object") {
        if (key !== "dateTo") {
          if (value == null || validator.isEmpty(value)) {
            isValid = false;
            errors[key] = translations.required_field.replace(
              "%s",
              displayedLabels[key]
            );
          }
        }
      }
    }

    setFormEndErrors(errors);
    return isValid;
  };
  /*
  const getRowClassName = (params) => {
    return "defaultFont";
  };
  */
  const columns = [
    {
      field: "appartment",
      headerName: translations.rent_property,
      flex: 3,
      valueGetter: (params) => params.row.appartment.name,
    },
    {
      field: "tenant",
      headerName: translations.tenant,
      flex: 3,
      valueGetter: (params) => params.row.tenant.name,
    },
    {
      field: "dateFrom",
      headerName: translations.from_date,
      flex: 1,
      valueFormatter: formatDate,
      editable: true,
      //headerClassName: "defaultFont",
    },
    {
      field: "dateTo",
      headerName: translations.to_date,
      flex: 1,
      valueFormatter: formatDate,
      editable: true,
      //headerClassName: "defaultFont",
    },
    {
      field: "frequency",
      headerName: translations.frequency_payment,
      flex: 1,
      editable: true,
      valueFormatter: (params) =>
        translate(translations, params.value.toLowerCase()),
    },
  ];
  const auth = `Bearer ${getToken()}`;
  const requestOptions = {
    headers: {
      Authorization: auth,
    },
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer className="toolbarContainer">
        <Button
          onClick={openDialogCreate}
          variant="outlined"
          color="primary"
          disabled={allAppartments.length === 0}
        >
          {translations.add}
        </Button>
        <Button
          onClick={openDialogDelete}
          variant="outlined"
          color="primary"
          disabled={!canDo.canDelete}
        >
          {translations.delete}
        </Button>
        <Button
          onClick={openDialogEnd}
          variant="outlined"
          color="primary"
          disabled={!canDo.canClose}
        >
          {translations.close_contract}
        </Button>
        <Button
          onClick={gotoContractPricesPage}
          variant="outlined"
          color="primary"
          disabled={selectionModel.length === 0}
        >
          {translations.price}
        </Button>
        <Button
          onClick={gotoContractChargesPage}
          variant="outlined"
          color="primary"
          disabled={selectionModel.length === 0}
        >
          {translations.charges}
        </Button>
      </GridToolbarContainer>
    );
  };

  if (!getToken() || getToken() === null) {
    return <Login setToken={setToken} />;
  }
  return (
    <div>
      <h1>{translations.contracts}</h1>
      <ErrorMessage message={errorMessage} />
      <Message message={message} timestamp={{ uidmessage }} />
      {loading ? (
        <p>{translations.loading}</p>
      ) : (
        <div className="flex flexHorizontalCenter">
          <div className="dataGridContainer">
            <DataGrid
              sx={{
                color: "primary.main",
              }}
              localeText={
                dataGridTexts.components.MuiDataGrid.defaultProps.localeText
              }
              rows={data}
              columns={columns}
              editMode="cell"
              components={{
                Toolbar: CustomToolbar,
              }}
              selectionModel={selectionModel}
              onRowSelectionModelChange={(newSelectionModel) => {
                handleSelectionChange(newSelectionModel);
              }}
            />
          </div>
        </div>
      )}
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSaveDialog();
          }
        }}
      >
        <DialogTitle>
          {dialogMode === "edit"
            ? translations.update_contract
            : translations.new_contract}
        </DialogTitle>
        <DialogContent className={calendarDialogClassName}>
          <br></br>
          <Grid container spacing={2} direction="column">
            {Object.entries(formValues).map(([key, value]) => {
              if (key !== "id" && key !== "dateTo") {
                //appartment
                if (key === "appartment") {
                  return (
                    <Grid item key={key}>
                      <InputLabel id="select-label-appartment">
                        {translations.rent_property}
                      </InputLabel>
                      <Select
                        labelId="select-label-appartment"
                        id="select-appartment"
                        value={value}
                        label={translations.rent_property}
                        onChange={handleTextFieldChange(key)}
                        style={{ width: "100%" }}
                      >
                        {allAppartments.map((app, index) => (
                          <MenuItem key={app.id} value={app.id}>
                            {app.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  );
                }
                //tenant
                else if (key === "tenant") {
                  return (
                    <Grid item key={key}>
                      <InputLabel id="select-label-tenant">
                        {translations.tenant}
                      </InputLabel>
                      <Select
                        labelId="select-label-tenant"
                        id="select-tenant"
                        value={value}
                        label={translations.tenant}
                        onChange={handleTextFieldChange(key)}
                        style={{ width: "100%" }}
                      >
                        {allTenants.map((tenant, index) => (
                          <MenuItem key={tenant.id} value={tenant.id}>
                            {tenant.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  );
                } else if (key === "dateFrom") {
                  return (
                    <Grid item key={key}>
                      <InputLabel id="select-label-dateFrom">
                        {translations.from_date}
                      </InputLabel>
                      <div onKeyDown={(e) => e.preventDefault()}>
                        <DatePicker
                          showIcon
                          selected={dateFrom}
                          onChange={(date) => handleDateFromChange(date)}
                          locale={localStorage
                            .getItem("userLanguage")
                            .substring(0, 2)}
                          dateFormat="P"
                          onCalendarClose={handleCalendarEditClose}
                          onCalendarOpen={handleCalendarEditOpen}
                        />
                      </div>
                    </Grid>
                  );
                } else if (key === "lang") {
                  return (
                    <Grid item key={key}>
                      <InputLabel
                        id="select-label-lang"
                        title={translations.language_documents_comment}
                      >
                        {translations.language_documents}
                      </InputLabel>
                      <Select
                        id={key}
                        value={value}
                        onChange={handleTextFieldChange(key)}
                        error={!!formErrors[key]}
                      >
                        {languages.map((languageCode) => (
                          <MenuItem
                            key={key + "_" + languageCode}
                            value={languageCode}
                          >
                            {
                              translations[
                                `language_${languageCode.substring(0, 2)}`
                              ]
                            }
                          </MenuItem>
                        ))}
                      </Select>
                      {formErrors[key] && (
                        <FormHelperText error>{formErrors[key]}</FormHelperText>
                      )}
                    </Grid>
                  );
                } else if (key === "frequency") {
                  return (
                    <Grid item key={key}>
                      <InputLabel id="select-label-frequency">
                        {translations.frequency_payment}
                      </InputLabel>
                      <Select
                        id={key}
                        value={value}
                        onChange={handleTextFieldChange(key)}
                        error={!!formErrors[key]}
                      >
                        {frequencies.map((f) => (
                          <MenuItem key={key + "_" + f} value={f}>
                            {translations[f.toLowerCase()]}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  );
                }
                //id and inner mongoose attributes must be hidden as appartment and tenant
                else
                  return (
                    <Grid item key={key}>
                      <TextField
                        label={displayedLabels[key]}
                        inputRef={(ref) => (textFieldsRefs.current[key] = ref)}
                        value={value}
                        onChange={handleTextFieldChange(key)}
                        error={!!formErrors[key]}
                        helperText={formErrors[key]}
                      />
                    </Grid>
                  );
              }
              return null;
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{translations.cancel}</Button>
          <Button onClick={handleSaveDialog}>{translations.save}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDelete} onClose={handleCloseDeleteDialog}>
        <DialogTitle>{translations.deletion_confirmation_title}</DialogTitle>
        <DialogContent>{translations.deletion_confirmation}</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>
            {translations.cancel}
          </Button>
          <Button onClick={handleConfirmDeleteDialog}>
            {translations.confirm}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openEnd} onClose={handleCloseEndDialog}>
        <DialogTitle>{translations.close_contract_title}</DialogTitle>
        <DialogContent className={calendarDialogClassName}>
          <Grid container spacing={2} direction="column">
            <Grid item key={"dateTo"}>
              <InputLabel id="select-label-dateTo">
                {translations.to_date}
              </InputLabel>
              <div onKeyDown={(e) => e.preventDefault()}>
                <DatePicker
                  showIcon
                  selected={dateTo}
                  minDate={minDateTo}
                  onChange={(date) => handleDateToChange(date)}
                  locale={localStorage.getItem("userLanguage").substring(0, 2)}
                  className="datePicker"
                  dateFormat="P"
                  onCalendarClose={handleCalendarEndClose}
                  onCalendarOpen={handleCalendarEndOpen}
                />
              </div>
            </Grid>
          </Grid>
          <div className="warningDialogMessage">
            {translations.action_cant_be_undone}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEndDialog}>{translations.cancel}</Button>
          <Button onClick={handleConfirmEndDialog}>
            {translations.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Contracts;
