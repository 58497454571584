import React, { useState, useEffect, useContext } from "react";
import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import {
  FormControlLabel,
  RadioGroup,
  FormLabel,
  FormHelperText,
  FormControl,
  Radio,
  Button,
  MenuItem,
  Select,
  Grid,
  TextField,
} from "@mui/material";
import * as constants from "../../utils/constants";
import ErrorMessage from "../../Components/Message";
import { fetchTranslations } from "../../utils/hooks";
import validator from "validator";
import {
  getToken,
  fetchData,
  fetchDataFromAPI,
  updateData,
  formatDate,
  useErrorHandler,
  isStrongPassword,
} from "../../utils/hooks";
import { TranslationContext } from "../../Components/TranslationContext";

function User() {
  const { handleAPIError } = useErrorHandler();
  const [formValues, setFormValues] = useState({
    id: "",
    username: "",
    first_name: "",
    last_name: "",
    street: "",
    zipcode: "",
    city: "",
    lang: "",
    singular: "",
    valid_until: "",
    new_password: "",
    new_password_again: "",
  });
  const { translations, updateTranslations } = useContext(TranslationContext);

  const [languages, setLanguages] = useState([]);
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(null);
  const [timeOutDuration, setTimeOutDuration] = useState(3000);
  const [previousLanguage, setPreviousLanguage] = useState("");

  const displayedLabels = {
    username: translations.email,
    first_name: translations.first_name,
    last_name: translations.last_name,
    street: translations.street,
    zipcode: translations.zipcode,
    city: translations.city,
    lang: translations.language_display,
    singular: translations.account_using,
    valid_until: translations.subscription_ending,
    new_password: translations.new_password,
    new_password_again: translations.new_password_again,
  };

  const fieldType = {
    username: "text",
    first_name: "text",
    last_name: "text",
    street: "text",
    zipcode: "text",
    city: "text",
    lang: "select",
    docs_lang: "select",
    singular: "radio",
    valid_until: "none",
    new_password: "text",
    new_password_again: "text",
  };

  const [formErrors, setFormErrors] = useState({
    first_name: "",
    last_name: "",
    street: "",
    zipcode: "",
    city: "",
    lang: "",
    docs_lang: "",
    singular: "",
    new_password: "",
    new_password_again: "",
  });

  const auth = `Bearer ${getToken()}`;
  const requestOptions = {
    headers: {
      Authorization: auth,
    },
  };

  const fetchUserData = () => {
    let uid = localStorage.getItem("userId");
    fetchData(API_URL + "/" + uid, requestOptions)
      .then((data) => {
        data.new_password = "";
        data.new_password_again = "";
        setFormValues(data);
        setPreviousLanguage(data.lang);
      })
      .catch((error) => handleAPIError(error));
  };

  const fetchLanguages = () => {
    fetchDataFromAPI(
      API_URL_LANGUAGES,
      requestOptions,
      setLanguages,
      false
    ).catch((error) => handleAPIError(error));
  };

  const handleTextFieldChange = (key) => (event) => {
    let updatedValue = event.target.value;

    // For zipcode
    if (key === "zipcode") {
      // replace all non-numeric characters by nothing
      updatedValue = updatedValue.replace(/\D/g, "");
    }

    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: updatedValue,
    }));
  };
  const API_URL = `${constants.API_URL}user`;
  const API_URL_LANGUAGES = `${constants.API_URL}languages`;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      formValues.id = localStorage.getItem("userId");
      updateData(API_URL, formValues.id, formValues, getToken())
        .then((response) => {
          if (previousLanguage !== formValues.lang) {
            async function loadTranslations() {
              try {
                const translationsData = await fetchTranslations(
                  formValues.lang
                );
                localStorage.setItem(
                  "translations",
                  JSON.stringify(translationsData)
                );
                updateTranslations(translationsData);
                localStorage.setItem("userLanguage", formValues.lang);
              } catch (error) {
                handleAPIError(error);
              }
            }

            loadTranslations();
          }
          setMessage(translations.update_done);
          setMessageType("info");
          setTimeOutDuration(timeOutDuration + 1);
        })
        .catch((error) => {
          handleAPIError(error);
        });
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {
      name: "",
      street: "",
      zipcode: "",
      city: "",
      email: "",
      lang: "",
      docs_lang: "",
      singular: "",
      new_password: "",
      new_password_again: "",
    };

    /**
     * All fields are mandatory excepted password update
     * Nevertheless, if first password field is set, second
     * field must be set also
     */
    for (const [key, value] of Object.entries(formValues)) {
      if (!key.startsWith("new_password")) {
        if (value === null || validator.isEmpty(value)) {
          isValid = false;
          errors[key] = translations.required_field.replace(
            "%s",
            displayedLabels[key]
          );
        }
      } else if (key === "new_password" && value.length > 0) {
        if (value !== formValues["new_password_again"]) {
          errors["new_password_again"] = translations.new_password_mismatch;
          isValid = false;
        } else if (!isStrongPassword(value)) {
          errors["new_password_again"] =
            "le mot de passe n'est pas assez solide";
          errors["new_password"] = "le mot de passe n'est pas assez robuste";
          isValid = false;
        }
      }
      setFormErrors(errors);
    }
    return isValid;
  };

  useEffect(() => {
    fetchUserData();
    fetchLanguages();
  }, []);

  return (
    <div>
      <ErrorMessage
        message={message}
        timeout={timeOutDuration}
        type={messageType}
      />
      <h1>{translations.profile_update_title}</h1>
      <div className="mainPageContainer">
        <form onSubmit={handleSubmit}>
          <div className="flex flexHorizontalCenter">
            {translations.subscription_ending}
            {formatDate({ value: formValues.valid_until })}
          </div>
          <br />
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Grid container spacing={2} direction="column">
                {Object.entries(formValues).map(([key, value]) => {
                  if (key !== "id" && !key.startsWith("new_password")) {
                    return (
                      <Grid item key={key}>
                        {fieldType[key] === "text" && (
                          <TextField
                            label={displayedLabels[key]}
                            value={value}
                            onChange={handleTextFieldChange(key)}
                            error={!!formErrors[key]}
                            helperText={formErrors[key]}
                            color="primary"
                          />
                        )}
                      </Grid>
                    );
                  }
                  return null;
                })}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={1} direction="column">
                {Object.entries(formValues).map(([key, value]) => {
                  if (key !== "id") {
                    return (
                      <Grid item key={key}>
                        {fieldType[key] === "radio" && key === "singular" && (
                          <div>
                            <FormControl component="fieldset">
                              <FormLabel id="demo-controlled-radio-buttons-group">
                                {translations.account_using}
                              </FormLabel>
                              <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={value}
                                onChange={handleTextFieldChange(key)}
                                row // Utilisez row pour afficher les boutons sur la même ligne
                              >
                                <FormControlLabel
                                  value="1"
                                  control={<Radio />}
                                  label={
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <PersonIcon />
                                      <span>
                                        &nbsp;{translations.individual_account}
                                      </span>
                                    </div>
                                  }
                                />
                                <FormControlLabel
                                  value="2"
                                  control={<Radio />}
                                  label={
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <GroupsIcon />
                                      <span>
                                        &nbsp;{translations.group_account}
                                      </span>
                                    </div>
                                  }
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        )}
                        {fieldType[key] === "select" && key === "lang" && (
                          <div>
                            <FormControl component="fieldset">
                              <FormLabel id="demo-controlled-radio-buttons-group">
                                {translations.language_display}
                              </FormLabel>
                              <Select
                                id={key}
                                value={value}
                                onChange={handleTextFieldChange(key)}
                                error={!!formErrors[key]}
                              >
                                <MenuItem value="">
                                  {translations.select_an_option}
                                </MenuItem>
                                {languages.map((languageCode) => (
                                  <MenuItem
                                    key={key + "_" + languageCode}
                                    value={languageCode}
                                  >
                                    {
                                      translations[
                                        `language_${languageCode.substring(
                                          0,
                                          2
                                        )}`
                                      ]
                                    }
                                  </MenuItem>
                                ))}
                              </Select>
                              {formErrors[key] && (
                                <FormHelperText error>
                                  {formErrors[key]}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </div>
                        )}
                        {fieldType[key] === "text" &&
                          key.startsWith("new_password") && (
                            <div>
                              {key === "new_password" && (
                                <FormControl component="fieldset">
                                  <h3 id="password_update_text">
                                    {translations.modify_password}
                                    &nbsp;
                                    <Tooltip
                                      title={translations.password_rules}
                                      arrow
                                    >
                                      <InfoIcon></InfoIcon>
                                    </Tooltip>
                                  </h3>
                                </FormControl>
                              )}
                              <TextField
                                label={displayedLabels[key]}
                                value={value}
                                onChange={handleTextFieldChange(key)}
                                error={!!formErrors[key]}
                                helperText={formErrors[key]}
                                color="primary"
                              />
                            </div>
                          )}
                      </Grid>
                    );
                  }
                  return null;
                })}
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2} direction="column">
            <div className="flex flexHorizontalCenter">
              <Button type="submit" variant="contained" color="primary">
                {translations.save}
              </Button>
            </div>
          </Grid>
        </form>
      </div>
    </div>
  );
}

export default User;
